import React, { useEffect, useState, useRef } from "react";
import ChartistGraph from "react-chartist";
import { makeStyles } from "@mui/styles";
import Store from "@mui/icons-material/Store";
import {
  AddBoxOutlined,
  KeyboardDoubleArrowRight,
  DateRange,
  LocalOffer,
  Update,
  AccessTime,
  Accessibility,
  OpenInNewOutlined,
  DriveFileRenameOutlineOutlined
} from "@mui/icons-material";
import { Link } from "react-router-dom";
// core components
import { reconUnitFormData } from "../../variables/ReconUnitLevelData";
import GridItem from "../../components/Grid/GridItem.js";
import Snackbar from "../../components/Snackbar/Snackbar.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import {
  showAlertNotification,
  showFailureNotification,
} from "../../action-handlers/NotificationsHandler";
import {
  API_RESOLVEX_TRENDS_FAILED,
  API_RECONX_TRENDS_FAILED,
  API_RECON_PROCESS_FAILED,
  API_RESOLVEX_KEYMETRIC_FAILED,
  API_RECONX_KEYMETRIC_FAILED,
  USER_SESSION_TIME_OUT,
} from "../../messages/DashboardMessages";

import { notificationParams } from "../../variables/general";

import {
  stylizedLineChart,
  stylizedBarChart,
  stylizedLineChart1,
} from "../../variables/charts";

import { fetchUserDetailsByLogin } from "../../action-handlers/LoginActionsHandler";
import {
  fetchReconUnitsData,
  fetchLandingReconData,
  fetchLandingResolveData,
  fetchCombinedTrendMetrices,
  fetchResolveTrendsData,
} from "../../action-handlers/DashboardActionHandler";

import Loader from "../../components/Loader/Loader";

import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import Skeleton from "@mui/material/Skeleton";

import { ReactComponent as PageSetting } from "../../assets/img/page-setting.svg";
import { ReactComponent as PagePercentage } from "../../assets/img/page-percentage.svg";
import { ReactComponent as TotalList } from "../../assets/img/total-list.svg";
import { ReactComponent as Tick } from "../../assets/img/tick.svg";
import "./ChartTooltip.css";

const useStyles = makeStyles(styles);

const landingPageReconCardData = {};
const landingPageResolveCardData = {};
const reconCiliationTrendsData = {};
const resolveXOpenTrendsData = {};
const resolveXCloseTrendsData = {};
var globalUserState = {};

const PieChart = ({ percentage }) => {
  const slicePercentage = Math.min(Math.max(percentage, 0), 100);

  const renderSlice = () => {
    const sliceStyle = {
      transform: `rotate(${(slicePercentage / 100) * 360}deg)`,
      backgroundColor: "blue",
    };

    return (
      <div className="slice">
        <div className="slice-inner" style={sliceStyle}></div>
      </div>
    );
  };

  return (
    <div className="pie-chart">
      <div className="slice-container">{renderSlice()}</div>
    </div>
  );
};

export default function Dashboard() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  const [isDashboardLoading, setDashboardLoading] = useState(true);
  const [isReconTrendsLoaded, setReconTrendsLoaded] = useState(false);
  const [isResolveTrendsLoaded, setResolveTrendsLoaded] = useState(false);
  const [isReconUnitDataLoaded, setReconUnitDataLoaded] = useState(false);
  const [isReconCardsDataLoaded, setReconCardsDataLoaded] = useState(false);
  const [isResolveCardsDataLoaded, setResolveCardsDataLoaded] = useState(false);
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const UserAuthContext = React.useContext(UserAuthDetailsContext);

  const selectedItem = UserAuthContext?.state?.originalMenuHierarchy
    ?.filter((mh) => mh.menuIdentifier.toLowerCase() === "admin")[0]
    ?.subMenus.filter(
      (sm) => sm.menuIdentifier.toLowerCase() === "ops process"
    )[0];

  window.sessionStorage.setItem("selectedItem", JSON.stringify(selectedItem));

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);

    if (isDashboardLoading) {
      if (
        UserAuthContext.state.userId === undefined ||
        UserAuthContext.state.userId === 0
      ) {
        //this means refresh or session of browser got refreshed. So lets fetch the user details from Database
        /// for doing lets get the loginName from sessionStorage see if it is available, try fetching the details

        if (
          UserAuthContext.state.loginName !== undefined ||
          UserAuthContext.state.loginName !== ""
        ) {
          //this means user had logged in earlier try fetching from sessionStorage loginName
          fetchUserDetailsByLogin(
            UserAuthContext.state.loginName,
            callBackFetchUserDetailsByLogin
          );
        }
      } else {
        let userState = {
          userId: UserAuthContext.state.userId,
          departmentName: UserAuthContext.state.departmentName,
          clientOrgId: UserAuthContext.state.clientOrgId,
        };
        loadInitialPageData(userState);
      }
    }

    return () => {
      console.log("Component unloading cleanup to be done here!!!");
    };
  }, [isDashboardLoading]);

  const loadInitialPageData = (userState) => {
    globalUserState = userState;
    let userId = userState.userId;

    fetchReconUnitsData(userId, callbackFetchReconUnitData);
    fetchLandingReconData(userId, callbackFetchLandingReconData);
    fetchLandingResolveData(userId, callbackFetchLandingResolveData);
  };

  const callBackFetchUserDetailsByLogin = (userDetailsState, apiStatus) => {
    if (apiStatus) {
      UserAuthContext.saveUserDetailsAuthState(userDetailsState);
      loadInitialPageData(userDetailsState);
    } else {
      //failure could be due to token expiry, raise an alert saying Token Expired and route to login page
      showAlertNotification(USER_SESSION_TIME_OUT, callbackNotification);
      UserAuthContext.clearUserDetailsAuthState();
      window.location.assign("/");
    }
  };

  const loadTrendsData = (userId) => {
    // this needs to be calling for all recon units
    //when there is no dependency on the reconunit it can be called directly in useEffect instead of calling from callbackfetchReconuNits

    fetchCombinedTrendMetrices(
      "3months",
      "recon",
      userId,
      callBackReconTrendsData
    );
  };

  const callBackResolveTrendsData = (
    resolveOpenTrendsData,
    resolveCloseTrendsData,
    apiStatus
  ) => {
    if (apiStatus) {
      resolveXOpenTrendsData.labels = resolveOpenTrendsData.labels;
      resolveXOpenTrendsData.series = resolveOpenTrendsData.series;

      resolveXCloseTrendsData.labels = resolveCloseTrendsData.labels;
      resolveXCloseTrendsData.series = resolveCloseTrendsData.series;

      setResolveTrendsLoaded(true);
      setReconUnitDataLoaded(true);
      validateIfFullDataLoaded();
    } else {
      showFailureNotification(API_RESOLVEX_TRENDS_FAILED, callbackNotification);
    }
  };

  const callBackReconTrendsData = (reconTrendsData, apiStatus) => {
    if (apiStatus) {
      reconCiliationTrendsData.labels = reconTrendsData.labels;
      reconCiliationTrendsData.series = reconTrendsData.series;
      //stylizedLineChart.data.labels = reconTrendsData.labels;
      //stylizedLineChart.data.series = reconTrendsData.series;

      //console.log("stylizedLineChart:::", stylizedLineChart.data);
      setReconTrendsLoaded(true);
      validateIfFullDataLoaded();
    } else {
      showFailureNotification(API_RECONX_TRENDS_FAILED, callbackNotification);
    }
  };

  const callbackNotification = (notificationObj) => {
    setNotificationObj(notificationObj);
  };

  const callbackFetchReconUnitData = (
    userId,
    assignedReconUnitData,
    reconUnitDropDownData,
    apiStatus
  ) => {
    if (apiStatus) {
      //calling the loadTrendsData once the reconunits are fetched. Hence placed in callback method
      //loadTrendsData(assignedReconUnitData[0].reconUnitId);// This load API to be changed for all RECON UNITS in Dashboard
      //let userId = UserAuthContext.state.userId;//"6";// replace it with proper userId
      let selectedFlowDefnIdId = "SayaReconResolver"; //For now all the exception management is done using SayaReconResolver
      let userAssignedReconUnits = [];
      reconUnitDropDownData.forEach((item) => {
        userAssignedReconUnits.push(item.value);
      });

      let userDepartmentId = globalUserState.departmentName;
      let orgId = globalUserState.clientOrgId;

      /* Hiding Dashabord ResolveX Closure Trend and ResolveX Open Items Trend */
      // fetchResolveTrendsData(
      //   userId,
      //   userDepartmentId,
      //   selectedFlowDefnIdId,
      //   orgId,
      //   userAssignedReconUnits,
      //   callBackResolveTrendsData
      // );

      loadTrendsData(userId);
      validateIfFullDataLoaded();
    } else {
      showFailureNotification(API_RECON_PROCESS_FAILED, callbackNotification);
    }
  };

  const handleCloseNotification = () => {
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };

  const validateIfFullDataLoaded = () => {
    if (
      isReconCardsDataLoaded &&
      isReconUnitDataLoaded &&
      isResolveCardsDataLoaded &&
      isReconTrendsLoaded &&
      isResolveTrendsLoaded
    ) {
      setDashboardLoading(false);
      setReconUnitDataLoaded(false);
      setResolveCardsDataLoaded(false);
      setReconCardsDataLoaded(false);
      setReconTrendsLoaded(false);
      setResolveTrendsLoaded(false);
    }
  };

  const callbackFetchLandingReconData = (landingPageReconData, apiStatus) => {
    if (apiStatus) {
      var arrayLength = landingPageReconData.length;
      for (var i = 0; i < arrayLength; i++) {
        landingPageReconCardData.averageReconPercent =
          landingPageReconData[i].averageReconPercent;
        landingPageReconCardData.avgOptimizerPercentage =
          landingPageReconData[i].avgOptimizerPercentage;
        landingPageReconCardData.avgOptimizerPercentageTitle =
          landingPageReconData[i].avgOptimizerPercentageTitle;
        landingPageReconCardData.avgReconPercentageTitle =
          landingPageReconData[i].avgReconPercentageTitle;
        landingPageReconCardData.optimizerRunDate =
          landingPageReconData[i].optimizerRunDate;
        landingPageReconCardData.optimizerRunDateTitle =
          landingPageReconData[i].optimizerRunDateTitle;
        landingPageReconCardData.reconRunDate =
          landingPageReconData[i].reconRunDate;
        landingPageReconCardData.reconRunDateTitle =
          landingPageReconData[i].reconRunDateTitle;
        landingPageReconCardData.reconUnitsConfiguredTitle =
          landingPageReconData[i].reconUnitsConfiguredTitle;
        landingPageReconCardData.totReconUnits =
          landingPageReconData[i].totReconUnits;
      }
      setReconCardsDataLoaded(true);
      validateIfFullDataLoaded();
    } else {
      showFailureNotification(
        API_RECONX_KEYMETRIC_FAILED,
        callbackNotification
      );
    }
  };

  const callbackFetchLandingResolveData = (
    landingPageResolveData,
    apiStatus
  ) => {
    if (apiStatus) {
      landingPageResolveCardData.flowUnitsConfiguredTitle =
        landingPageResolveData.flowUnitsConfiguredTitle;
      landingPageResolveCardData.flowUnitsConfigured =
        landingPageResolveData.flowUnitsConfigured;
      landingPageResolveCardData.openResolveItemsTitle =
        landingPageResolveData.openResolveItemsTitle;
      landingPageResolveCardData.openResolveItemsCount =
        landingPageResolveData.openResolveItemsCount;
      setResolveCardsDataLoaded(true);
      validateIfFullDataLoaded();
    } else {
      showFailureNotification(
        API_RESOLVEX_KEYMETRIC_FAILED,
        callbackNotification
      );
    }
  };

  return (
    <div>
      {/* {loading ? (
        <Loader />
      ) : ( */}
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card className={classes.pinkVariant}>
              <Link to="/saya-platform/admin/ops-home?type=ReconX">
                <CardHeader color="primary" stats icon>
                  <CardIcon color="primary">
                    <PageSetting />
                  </CardIcon>
                  <p className={classes.cardTitle}>
                    {landingPageReconCardData.reconUnitsConfiguredTitle || (
                      <Skeleton animation="wave" />
                    )}
                  </p>
                  <h3 className={classes.cardTitle}>
                    {landingPageReconCardData.totReconUnits < 10
                      ? `0${landingPageReconCardData.totReconUnits}`
                      : landingPageReconCardData.totReconUnits || (
                          <Skeleton
                            variant="text"
                            width={30}
                            animation="wave"
                            height={20}
                          />
                        )}
                  </h3>
                </CardHeader>
              </Link>
              <CardFooter stats>
                <Link
                  to="/saya-platform/admin/ops-home/ops-crud"
                  state={{
                    ...reconUnitFormData,
                    new: true,
                    selectedItem: JSON.stringify(
                      (UserAuthContext?.state?.menuHierarchy || [])
                        ?.filter((mh) => mh.name === "Admin")[0]
                        ?.subMenus?.filter(
                          (sm) => sm.menuIdentifier === "Ops process"
                        )[0]
                    ),
                  }}
                >
                  <div className={classes.stats}>
                    <AddBoxOutlined />
                    Create new Ops Process
                  </div>
                  <KeyboardDoubleArrowRight />
                </Link>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Link to="/saya-platform/saya-reconx">
              <Card className={classes.greenVariant}>
                <CardHeader color="primary" stats icon>
                  <CardIcon color="primary">
                    {/* <PieChart percentage={76} /> */}
                    {/* <CircleLoader /> */}
                    {/* <PagePercentage /> */}
                    <ChartistGraph
                      className="ct-chart"
                      data={{
                        series: [
                          {
                            value: Number(
                              landingPageReconCardData.averageReconPercent
                            ),
                            name: "series1",
                          },
                          {
                            value: Number(
                              100 - landingPageReconCardData.averageReconPercent
                            ),
                            name: "series2",
                          },
                        ],
                      }}
                      type="Pie"
                      options={{
                        width: "66px",
                        height: "65px",
                        donut: false,
                      }}
                    />
                  </CardIcon>
                  <p className={classes.cardTitle}>
                    {landingPageReconCardData.avgReconPercentageTitle || (
                      <Skeleton animation="wave" />
                    )}
                  </p>
                  <h3 className={classes.cardTitle}>
                    {landingPageReconCardData.averageReconPercent || (
                      <Skeleton
                        variant="text"
                        width={30}
                        animation="wave"
                        height={20}
                      />
                    )}
                    {landingPageReconCardData.averageReconPercent && (
                      <small>%</small>
                    )}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <OpenInNewOutlined />
                    Navigate to ReconX
                  </div>
                  <KeyboardDoubleArrowRight />
                </CardFooter>
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Link to="/saya-platform/admin/ops-home?type=ResolveX">
              <Card className={classes.blueVariant}>
                <CardHeader color="primary" stats icon>
                  <CardIcon color="primary">
                    <TotalList />
                  </CardIcon>
                  <p className={classes.cardTitle}>
                    {landingPageResolveCardData.flowUnitsConfiguredTitle || (
                      <Skeleton animation="wave" />
                    )}
                  </p>
                  <h3 className={classes.cardTitle}>
                    {landingPageResolveCardData.flowUnitsConfigured < 10
                      ? `0${landingPageResolveCardData.flowUnitsConfigured}`
                      : landingPageResolveCardData.flowUnitsConfigured || (
                          <Skeleton
                            variant="text"
                            width={30}
                            animation="wave"
                            height={20}
                          />
                        )}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <DriveFileRenameOutlineOutlined />
                    Modify Recon Resolve Process
                  </div>
                  <KeyboardDoubleArrowRight />
                </CardFooter>
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Link to="/saya-platform/saya-resolvex">
              <Card className={classes.yellowVariant}>
                <CardHeader
                  color="primary"
                  stats
                  icon
                  className={classes.cardHeaderWrapper}
                >
                  <CardIcon color="primary">
                    <Tick />
                  </CardIcon>
                  <p className={classes.cardTitle}>
                    {landingPageResolveCardData.openResolveItemsTitle || (
                      <Skeleton animation="wave" />
                    )}
                  </p>
                  <h3 className={classes.cardTitle}>
                    {landingPageResolveCardData.openResolveItemsCount < 10
                      ? `0${landingPageResolveCardData.openResolveItemsCount}`
                      : landingPageResolveCardData.openResolveItemsCount || (
                          <Skeleton
                            variant="text"
                            width={30}
                            animation="wave"
                            height={20}
                          />
                        )}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <OpenInNewOutlined />
                     Navigate to ResolveX
                  </div>
                  <KeyboardDoubleArrowRight />
                </CardFooter>
              </Card>
            </Link>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card chart>
              <CardHeader>
                <h4 className={classes.cardTitle + " card-title-flag"}>
                  Reconciliation Trend
                </h4>
              </CardHeader>
              <CardBody color="primary" chart style={{ height: 330 }}>
                {reconCiliationTrendsData && stylizedLineChart && (
                  <ChartistGraph
                    className="ct-chart trend-chart"
                    data={reconCiliationTrendsData /*stylizedLineChart.data*/}
                    type="Line"
                    options={{ ...stylizedLineChart.options, height: 330 }}
                    listener={stylizedLineChart.animation}
                  />
                )}
                {/*<p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sales.
              </p>*/}
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> Last 10 Recon executions trend
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          {/* <GridItem xs={12} sm={12} md={6}>
            <ChartistGraph
              className="ct-chart"
              data={{
                series: [
                  { value: 73.61, name: "series1" },
                  { value: 26.39, name: "series2" }
                ],
              }}
              type="Pie"
              options={{
                width: '66px',
                height: '65px',
                donut: false,
              }}
              //listener={stylizedLineChart.animation}
            />
          </GridItem> */}
          {/* <GridItem xs={12} sm={12} md={6}>
            <Card chart>
              <CardHeader>
                <h4 className={classes.cardTitle + " card-title-flag"}>
                  ResolveX Closure Trend
                </h4>
              </CardHeader>
              <CardBody color="primary">
         
                <ChartistGraph
                  className="ct-chart"
                  data={ resolveXCloseTrendsData}
                  type="Bar"
                  options={stylizedBarChart.options}
                  responsiveOptions={stylizedBarChart.responsiveOptions}
                  listener={stylizedBarChart.animation}
                />
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> Week-Day wise closures
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card chart>
              <CardHeader>
                <h4 className={classes.cardTitle + " card-title-flag"}>
                  ResolveX Open Items Trend
                </h4>
              </CardHeader>
              <CardBody color="primary">
            
                <ChartistGraph
                  className="ct-chart"
                  data={ resolveXOpenTrendsData}
                  type="Line"
                  options={stylizedLineChart1.options}
                  listener={stylizedLineChart1.animation}
                />
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> Week Day wise Open Items
                </div>
              </CardFooter>
            </Card>
          </GridItem> */}
        </GridContainer>
        <Snackbar
          place={notificationObj.place}
          color={notificationObj.color}
          icon={notificationObj.icon}
          message={notificationObj.message} //"Welcome to MATERIAL DASHBOARD React - a beautiful freebie for every web developer."
          open={notificationObj.open}
          closeNotification={handleCloseNotification}
          close={notificationObj.close}
        />
      </div>
    </div>
  );
}
